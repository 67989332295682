<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <div class="contianermobile">
            <div class="header">
                <mat-toolbar class="mat-toolbar-row">
                    <div class="left-col">
                        <mat-icon (click)="backClicked()" class="back">keyboard_arrow_left</mat-icon>
                    </div>
                    <div>
                        <span><img src="../../assets/logo/localhost.png" alt="" style="height: 27px;"></span>
                        <span class="title">Withdraw</span>
                        <button id="openModalButton" [hidden]="true" (click)="openmodel(success)">test</button>
                    </div>
                    <span class="example-spacer"></span>
                    <!-- <button mat-button class="example-icon" (click)="godetail()">
                   Withdraw Details
                </button> -->
                </mat-toolbar>
            </div>

            <div class="deopsitformblock-outer">
                <div class="col-12 deopsitformblock" *ngIf="this.type=='withdraw'">
                    <div class="setbalance" *ngIf="userwalletBalance">Wallet Balance<br><i class="fas fa-rupee-sign">
                            &nbsp;</i>{{userwalletBalance| number : '0.0-2'}}</div>
                    <div class="setbalance" *ngIf="!userwalletBalance">Wallet Balance<br><i class="fas fa-rupee-sign">
                            &nbsp;</i>00</div>
                    <form class="example-form" [formGroup]='withdrawForm' (ngSubmit)='withdrawamount()'>
                        <mat-form-field appearance="outline">
                            <mat-label>Withdraw Amount</mat-label>
                            <input matInput placeholder="Coins" type="number" formControlName='amount'>
                            <mat-icon style="color: #323232;" matSuffix>sentiment_very_satisfied</mat-icon>
                            <mat-hint *ngIf="filter">*Minimum Withdraw Amount is {{this.filData?.minLimit}} Coins</mat-hint>
                            
                        </mat-form-field>
                        <div>
                            <h2 class="setlabel1">Please Select Bank* <span class="example-icon" (click)="godetail()">
                                    +Add Bank</span></h2>
                            <mat-radio-group formControlName='type'>
                                <!-- <mat-radio-button  class="setbutton" *ngFor="let bank of withdrawmethod; let i = index" [checked]="i === 0" value="{{bank.name}}">{{bank?.name}} ({{bank?.type}})<span></span></mat-radio-button> -->
                                <mat-radio-button class="setbutton" *ngFor="let bank of withdrawmethod"
                                    value="{{bank.type}}" (change)="radioChange($event,bank)">{{bank?.name}}
                                    ({{bank?.type}})
                                    <!-- <span style="float:right">{{bank?.upi}}</span> -->
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="submit-btn">
                            <button mat-raised-button [disabled]="loginButtonDisable">
                                <div *ngIf="!loginButtonDisable ;else hiddengif">
                                <span  class="setspan">Withdraw
                                    Coins</span>
                            </div> 
                                <ng-template #hiddengif>
                                    <img src="assets/payment/circle.gif" width="20" height="20" alt="localost">
                                </ng-template></button>
                        </div>
                    </form>
                </div>

                <div class="col-12" *ngIf="this.type!='withdraw'">
                    <div class="row setamount">
                        <div class="col-12 setcolumn">
                            <span>Withdraw {{setamount}} Coins</span>
                        </div>
                    </div>
                    <form class="example-form" [formGroup]='idwithdrawForm' (ngSubmit)='idwithdrawamount()'>
                        <div>
                            <h2 class="setlabel1">Please Select Bank* <span class="example-icon" (click)="godetail()">
                                    +Add Bank</span></h2>
                            <mat-radio-group formControlName='type'>
                                <!-- <mat-radio-button  class="setbutton" *ngFor="let bank of withdrawmethod; let i = index" [checked]="i === 0" value="{{bank.name}}">{{bank?.name}} ({{bank?.type}})<span></span></mat-radio-button> -->
                                <mat-radio-button class="setbutton" *ngFor="let bank of withdrawmethod"
                                    value="{{bank.type}}" (change)="radioChange($event,bank)">{{bank?.name}}
                                    ({{bank?.type}})
                                    <!-- <span style="float:right">{{bank?.upi}}</span> -->
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="submit-btn">
                            <button mat-raised-button [disabled]="loginButtonDisable">
                                <div *ngIf="!loginButtonDisable ;else hiddengif">
                                    <span  class="setspan">Deposite
                                        Coins</span>
                                </div> 
                                    <ng-template #hiddengif>
                                        <img src="assets/payment/circle.gif" width="20" height="20" alt="localost">
                                    </ng-template>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>


<!-- Success Payment -->

<ng-template #success>
    <div class="modal-body">
        <mat-list class="setmat">
            <div class="text-right"><a type="button" class="close" data-dismiss="modal">
                    <mat-icon (click)="modalRef.hide()">close</mat-icon>
                </a></div>
            <div class="text-center" *ngIf="depositdata.success == true">

                <img src="assets/images/success.svg" />
                <h5 class="setamount">{{depositdata?.doc?.amount | currency:"&#8377;"}}</h5>
                <h4 class="trantion">{{depositdata?.message}}</h4>
                <button class="backbutton" (click)="modalRef.hide()" [routerLink]="['/dashboard']">Back To Home</button>
            </div>
            <div class="text-center" *ngIf="depositdata.success != true">
                <mat-icon class="setback1">cancel</mat-icon>
                <h5 class="setamount1">{{depositdata?.doc?.amount | currency:"&#8377;"}}</h5>
                <h4 class="trantion">{{depositdata?.message}}</h4>
                <button class="backbutton" (click)="modalRef.hide()" [routerLink]="['/dashboard']">Back To Home</button>
            </div>

        </mat-list>

    </div>
</ng-template>