<mat-sidenav-container fullscreen>
  <mat-sidenav-content>
  <app-backheader></app-backheader>
    <div class="main-content">
      

      <!-- offerDesign -->
      <div  class="offer_header active" >
        <div class="left" > 
          <a href='#cricket' [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']" [routerLink]="[ '/tabgames/1']"><img src="/assets/images/osgbook/ball.svg" id='cricket' alt=""></a> 
          <p id='cricket' > Cricket</p>
        </div>

        <div class="main">
          <a href='#soccer' [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"  [routerLink]="[ '/tabgames/2']" class='target'>
          <img src="/assets/images/osgbook/soccer.svg"  id='soccer' alt="">
          </a>
          <p>Soccer</p>
        </div>

        <div class="right">
          <a href='#tennis'[routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']" [routerLink]="[ '/tabgames/3']" class='target'>
          <img src="/assets/images/osgbook/tennis.svg" id='tennis' alt="">
          </a>
          <p>Tennis</p>
        </div>
        <div class="main">
          <a href='#casino' [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']" [routerLink]="[ '/tabgames/4']" class='target'>
          <img src="/assets/images/osgbook/cards-header-icon.svg" id='casino' alt="">
          </a>
          <p>Slots</p>
        </div>
        <div class="main">
          <a href='#casino1' [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']" [routerLink]="[ '/tabgames/5']" class='target'>
          <img src="/assets/images/osgbook/chips-header-icon.svg" id='casino1' alt="">
          </a>

          <p>Live Casino</p>
        </div>
        <div class="main" >
          <a href='#casino2' [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']" [routerLink]="[ '/tabgames/6']" class='target'>
          <img src="/assets/images/osgbook/dice-header-icon.svg" id='casino2' alt="">
          </a>
          <p>Casino</p>
        </div>
        
      </div>


      <div style="margin-bottom: 86px;">
      </div>
      <div *ngIf="marquetext" class="marquee-text">
        <!-- <mat-icon> rss_feed</mat-icon> -->
        <marquee width="100%" direction="left">{{marquetext}}</marquee>
      </div>

      <!-- Cricket -->
      <div *ngIf="id==1">
        <mat-tab-group class="tab-body" class="sport-details-tab">
          <mat-tab label="INPLAY" >
            <ng-container *ngIf="cricketData?.length">


              <div class="game-list-card">
                <div class="game-list-header">
                  <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
                  Matches
                  <!-- ({{cricketData?.length}}) -->
                  <mat-divider class="didvide" [vertical]="true"></mat-divider>

                </div>

                <div *ngFor="let x of cricketData" (click)="matchDetail(x.eventId, x.eventTypeId);">
                  <table class="tabledata"  *ngIf="x.marketBook.inplay == true">
                    <tr> 
                      <th style="text-align: center; width:25%;padding-top:20px">
                       
                       <div class="temflex"> <img   *ngIf="x?.imgArr" src="{{x?.imgArr[0]}}"  onerror="this.src='/assets/images/osgbook/ball.svg'"
                          class="flagimage">
                          <img  *ngIf="!x?.imgArr"  src='/assets/images/osgbook/ball.svg'
                          class="flagimage">
                        <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[0].runnerName}}</small></p>
                      </div>
                      </th>
                      <th style="text-align: center;width:50%;">
                        <small>{{x?.competitionName}}</small>
                        <div>
                          VS
                        </div>
                        <div>
                          <small class="timeshow">
                            {{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                            {{moment(x?.openDate).utcOffset("+05:30").format('D')}}
                            {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}
                          </small>
                        </div>
                      </th>
                      <th style="text-align: center; width:25%;padding-top:20px;">
                        <div class="temflex">
                        <img   *ngIf="x?.imgArr"  src="{{x?.imgArr[1]}}" onerror="this.src='/assets/images/osgbook/ball.svg'"
                          class="flagimage">
                          <img  *ngIf="!x?.imgArr"  src='/assets/images/osgbook/ball.svg'
                          class="flagimage">
                        <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[1].runnerName}}</small></p>
                        </div>
                      </th>
                    </tr>
                  </table>
                </div>
                <div style="text-align: center;" *ngIf="!checkInplayStatus(cricketData[0].openDate)"  >
                  <span>No Matches Found !</span>
              </div>
 
              </div>
            </ng-container>
            <ng-container *ngIf="cricketData?.length == '0' ">
              <span  class ="notmatch"> No Match Found !</span>
                      </ng-container>
          </mat-tab>
          <mat-tab label="TODAY">
            <ng-container *ngIf="cricketData?.length">
              <div class="game-list-card">
                <div class="game-list-header">
                  <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
                  Matches
                  <!-- ({{cricketData?.length}}) -->
                  <mat-divider class="didvide" [vertical]="true"></mat-divider>
                </div>
                <div *ngFor="let x of cricketData" (click)="matchDetail(x.eventId, x.eventTypeId);">
                  <table class="tabledata" *ngIf="checkTodayStatus(x.openDate)">
                    <tr>
                      <th style="text-align: center; width:25%;padding-top:20px">
                        <div class="temflex"> <img   *ngIf="x?.imgArr" src="{{x?.imgArr[0]}}" onerror="this.src='/assets/images/osgbook/ball.svg'"
                          class="flagimage">
                          <img  *ngIf="!x?.imgArr"  src='/assets/images/osgbook/ball.svg'
                          class="flagimage">
                        <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[0].runnerName}}</small></p>
                      </div>
                      </th>
                      <th style="text-align: center;width:50%;">
                        <small>{{x?.competitionName}}</small>
                        <div>
                          VS
                        </div>
                        <div>
                          <small class="timeshow">
                            {{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                            {{moment(x?.openDate).utcOffset("+05:30").format('D')}}
                            {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}
                          </small>

                        </div>
                      </th>
                      <th style="text-align: center; width:25%;padding-top:20px;">
                        <div class="temflex"><img   *ngIf="x?.imgArr" src="{{x?.imgArr[1]}}" onerror="this.src='/assets/images/osgbook/ball.svg'"
                          class="flagimage">
                          <img  *ngIf="!x?.imgArr"  src='/assets/images/osgbook/ball.svg'
                          class="flagimage">
                        <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[1].runnerName}}</small></p>
                      </div>
                      </th>

                    </tr>
                  </table>
                </div>
                <!-- <div style="text-align: center;" *ngIf="!checkTodayStatus(cricketData[0].openDate)"  >
                  <span>No Matches Found !</span>
              </div> -->
              </div>
            </ng-container>
            <ng-container *ngIf="cricketData?.length == '0' ">
              <div  class ="notmatch"> No Match Found !</div>
                      </ng-container>
          </mat-tab>
          <mat-tab label="UPCOMING">
            <ng-container *ngIf="cricketData?.length">
              <div class="game-list-card">
                <div class="game-list-header">
                  <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
                  Matches
                  <!-- ({{cricketData?.length}}) -->
                  <mat-divider class="didvide" [vertical]="true"></mat-divider>
                </div>

                <div *ngFor="let x of cricketData" (click)="matchDetail(x.eventId, x.eventTypeId);">
                  <table *ngIf="checkTomorrowStatus(x.openDate)" class="tabledata">

                    <tr>
                      <th style="text-align: center; width:25%;padding-top:20px">
                        <div class="temflex"> <img   *ngIf="x?.imgArr" src="{{x?.imgArr[0]}}" onerror="this.src='/assets/images/osgbook/ball.svg'"
                          class="flagimage">
                          <img  *ngIf="!x?.imgArr"  src='/assets/images/osgbook/ball.svg'
                          class="flagimage">
                        <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[0].runnerName}}</small></p>
                        </div>
                      </th>
                      <th style="text-align: center;width:50%;">
                        <small>{{x?.competitionName}}</small>
                        <div>
                          VS
                        </div>
                        <div>
                          <small class="timeshow">
                            {{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                            {{moment(x?.openDate).utcOffset("+05:30").format('D')}}
                            {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}
                          </small>

                        </div>
                      </th>
                      <th style="text-align: center; width:25%;padding-top:20px;">
                        <div class="temflex">
                        <img   *ngIf="x?.imgArr" src="{{x?.imgArr[1]}}" onerror="this.src='/assets/images/osgbook/ball.svg'"
                          class="flagimage">
                          <img  *ngIf="!x?.imgArr"  src='/assets/images/osgbook/ball.svg'
                          class="flagimage">
                        <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[1].runnerName}}</small></p>
                        </div>
                      </th>
                    </tr>
                  </table>
                </div>
                <!-- <div style="text-align: center;" *ngIf="cricketData[0].openDate < tomorrow"  >
                  <span>No Matches Found !</span>
              </div> -->

              </div>
            </ng-container>
            <ng-container *ngIf="cricketData?.length == '0' ">
              <div  class ="notmatch"> No Match Found !</div>
                      </ng-container>
          </mat-tab>
        </mat-tab-group>

      </div>
      <div *ngIf="id==2">
        <!-- Soccer -->
        <mat-tab-group class="tab-body" class="sport-details-tab">
          <mat-tab label="INPLAY">
        <ng-container *ngIf="soccerData?.length">
          <div class="game-list-card">
            <div class="game-list-header">
              <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
              Matches
              <!-- ({{cricketData?.length}}) -->
              <mat-divider class="didvide" [vertical]="true"></mat-divider>

            </div>

            <div  *ngFor="let x of soccerData" (click)="matchDetail(x.eventId, x.eventTypeId);">
              <table   *ngIf="x.marketBook.inplay == true" class="tabledata">
              <tr>
                <th style="text-align: center; width:25%;padding-top:20px">
                  <div class="temflex">  <img src="/assets/images/osgbook/soccer.svg"
                    class="flagimage">
                  <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[0]?.runnerName}}</small></p>
                </div>  
                </th>
                <th style="text-align: center;width:50%;">
                  <small>{{x?.competitionName}}</small>
                  <div class="">
                    VS
                  </div>
                  <div >
                    <small class="timeshow">
                      {{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                      {{moment(x?.openDate).utcOffset("+05:30").format('D')}}
                      {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}
                    </small>
                  </div>
                </th>
                <th style="text-align: center; width:25%;padding-top:20px;">
                  <div class="temflex">  <img src="/assets/images/osgbook/soccer.svg"
                    class="flagimage">
                  <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[1]?.runnerName}}</small></p>
                  </div>
                </th>
              </tr>
              </table>
            </div>
            
          </div>
        </ng-container>
        <ng-container *ngIf="soccerData?.length == '0' ">
          <div  class ="notmatch"> No Match Found !</div>
                  </ng-container>
        </mat-tab>
        <mat-tab label="TODAY">
          <ng-container *ngIf="soccerData?.length">
            <div class="game-list-card">
              <div class="game-list-header">
                <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
                Matches
                <!-- ({{cricketData?.length}}) -->
                <mat-divider class="didvide" [vertical]="true"></mat-divider>
  
              </div>
  
              <div  *ngFor="let x of soccerData" (click)="matchDetail(x.eventId, x.eventTypeId);">
                <table *ngIf="checkTodayStatus(x.openDate)" class="tabledata">
                <tr>
                  <th style="text-align: center; width:25%;padding-top:20px">
                    <div class="temflex"><img src="/assets/images/osgbook/soccer.svg"
                      class="flagimage">
                    <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[0]?.runnerName}}</small></p>
                  </div>
                  </th>
                  <th style="text-align: center;width:50%;">
                    <small>{{x?.competitionName}}</small>
                    <div class="">
                      VS
                    </div>
                    <div >
                      <small class="timeshow">
                        {{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                        {{moment(x?.openDate).utcOffset("+05:30").format('D')}}
                        {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}
                      </small>
                    </div>
                  </th>
                  <th style="text-align: center; width:25%;padding-top:20px;">
                    <div class="temflex">  <img src="/assets/images/osgbook/soccer.svg"
                      class="flagimage">
                    <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[1]?.runnerName}}</small></p>
                    </div>
                  </th>
                </tr>
                </table>
              </div>
              <div style="text-align: center;" *ngIf="!checkTodayStatus(soccerData[0].openDate)"  >
                <span>No Matches Found !</span>
            </div>
            </div>
          </ng-container>
          <ng-container *ngIf="soccerData?.length == '0' ">
            <div  class ="notmatch"> No Match Found !</div>
                    </ng-container> 
        </mat-tab>
        <mat-tab label="UPCOMING">
          <ng-container *ngIf="soccerData?.length">
            <div class="game-list-card">
              <div class="game-list-header">
                <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
                Matches
                <!-- ({{cricketData?.length}}) -->
                <mat-divider class="didvide" [vertical]="true"></mat-divider>
  
              </div>
  
              <div  *ngFor="let x of soccerData" (click)="matchDetail(x.eventId, x.eventTypeId);">
                <table *ngIf="checkTomorrowStatus(x.openDate)" class="tabledata">
                <tr>
                  <th style="text-align: center; width:25%;padding-top:20px">
                    <div class="temflex"><img src="/assets/images/osgbook/soccer.svg"
                      class="flagimage">
                    <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[0]?.runnerName}}</small></p></div>
                  </th>
                  <th style="text-align: center;width:50%;">
                    <small>{{x?.competitionName}}</small>
                    <div class="">
                      VS
                    </div>
                    <div >
                      <small class="timeshow">
                        {{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                        {{moment(x?.openDate).utcOffset("+05:30").format('D')}}
                        {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}
                      </small>
                    </div>
                  </th>
                  <th style="text-align: center; width:25%;padding-top:20px;">
                    <div class="temflex"> <img src="/assets/images/osgbook/soccer.svg"
                      class="flagimage">
                    <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[1]?.runnerName}}</small></p>
                    </div>
                  </th>
                </tr>
                </table>
              </div>
              <div style="text-align: center;" *ngIf="soccerData[0].openDate > tomorrow"  >
                <span>No Matches Found !</span>
            </div>
            </div>
          </ng-container>
          <ng-container *ngIf="soccerData?.length == '0' ">
            <div  class ="notmatch"> No Match Found !</div>
                    </ng-container>
        </mat-tab>

        </mat-tab-group>
      </div>


      <!-- Tennis -->
      <div *ngIf="id==3">
        <mat-tab-group class="tab-body" class="sport-details-tab">
          <mat-tab label="INPLAY">
        <ng-container *ngIf="tennisData?.length">
          <div class="game-list-card">
            <div class="game-list-header">
              Matches
              <mat-divider class="didvide" [vertical]="true"></mat-divider>

            </div>

            <div  *ngFor="let x of tennisData" (click)="matchDetail(x.eventId, x.eventTypeId);">
              <table class="tabledata"  *ngIf="x.marketBook.inplay == true">
                <tr>
                  <th style="text-align: center; width:25%;padding-top:20px">
                    <div class="temflex"><img src="/assets/images/osgbook/tennis.svg"
                      class="flagimage">
                     
                    <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[0]?.runnerName}}</small></p></div> 
                  </th>
                  <th style="text-align: center;width:50%;">
                    <small>{{x?.competitionName}}</small>
                    <div class="">
                      VS
                    </div>
                    <div >
                      <small class="timeshow">
                        {{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                        {{moment(x?.openDate).utcOffset("+05:30").format('D')}}
                        {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}
                      </small>
                    </div>
                  </th>
                  <th style="text-align: center; width:25%;padding-top:20px;">
                    <div class="temflex"><img src="/assets/images/osgbook/tennis.svg"
                      class="flagimage">
                    <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[1]?.runnerName}}</small></p>
                  </div>
                  </th>
                </tr>
              </table>
              
            </div>
            <!-- <div style="text-align: center;" *ngIf="!checkInplayStatus(tennisData[0].openDate)"  >
              <span>No Matches Found !</span>
          </div> -->
          </div>
        </ng-container>
        <ng-container *ngIf="tennisData?.length == '0' ">
          <div  class ="notmatch"> No Match Found !</div>
                  </ng-container>
        </mat-tab>
        <mat-tab label="TODAY">
          <ng-container *ngIf="tennisData?.length">
            <div class="game-list-card">
              <div class="game-list-header">
                <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
                Matches
                <!-- ({{cricketData?.length}}) -->
                <mat-divider class="didvide" [vertical]="true"></mat-divider>
  
              </div>
  
              <div  *ngFor="let x of tennisData" (click)="matchDetail(x.eventId, x.eventTypeId);">
                <table class="tabledata" *ngIf="checkTodayStatus(x.openDate)">
                  <tr  >
                    <th style="text-align: center; width:25%;padding-top:20px">
                      <div class="temflex"><img src="/assets/images/osgbook/tennis.svg"
                        class="flagimage">
                        
                      <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[0]?.runnerName}}</small></p>
                      </div>
                    </th>
                    <th style="text-align: center;width:50%;">
                      <small>{{x?.competitionName}}</small>
                      <div class="">
                        VS
                      </div>
                      <div >
                        <small class="timeshow">
                          {{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                          {{moment(x?.openDate).utcOffset("+05:30").format('D')}}
                          {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}
                        </small>
                      </div>
                    </th>
                    <th style="text-align: center; width:25%;padding-top:20px;">
                      <div class="temflex"> <img src="/assets/images/osgbook/tennis.svg"
                        class="flagimage">
                      <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[1]?.runnerName}}</small></p>
                      </div>
                    </th>
                  </tr>
                </table>
                
              </div>
              <!-- <div style="text-align: center;" *ngIf="!checkTodayStatus(tennisData[0].openDate)"  >
                <span>No Matches Found !</span>
            </div> -->
            </div>
          </ng-container>
          <ng-container *ngIf="tennisData?.length == '0' ">
            <div  class ="notmatch"> No Match Found !</div>
                    </ng-container>
          </mat-tab>
          <mat-tab label="UPCOMING">
            <ng-container *ngIf="tennisData?.length">
              <div class="game-list-card">
                <div class="game-list-header">
                  <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
                  Matches
                  <!-- ({{cricketData?.length}}) -->
                  <mat-divider class="didvide" [vertical]="true"></mat-divider>
                </div>
                <div  *ngFor="let x of tennisData" (click)="matchDetail(x.eventId, x.eventTypeId);">
                  <table class="tabledata" *ngIf="checkTomorrowStatus(x.openDate)">
                    <tr>
                      <th style="text-align: center; width:25%;padding-top:20px">
                        <div class="temflex"> <img src="/assets/images/osgbook/tennis.svg"
                          class="flagimage">
                          
                        <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[0]?.runnerName}}</small></p>
                      </div>
                      </th>
                      <th style="text-align: center;width:50%;">
                        <small>{{x?.competitionName}}</small>
                        <div class="">
                          VS
                        </div>
                        <div >
                          <small class="timeshow">
                            {{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                            {{moment(x?.openDate).utcOffset("+05:30").format('D')}}
                            {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}
                          </small>
                        </div>
                      </th>
                      <th style="text-align: center; width:25%;padding-top:20px;">
                        <div class="temflex"><img src="/assets/images/osgbook/tennis.svg"
                          class="flagimage">
                        <p style="line-height: 12px;padding:4px;"><small>{{x?.runners[1]?.runnerName}}</small></p></div>  
                      </th>
                    </tr>
                  </table>
                  
                </div>
                <div style="text-align: center;font-size: 11px;" *ngIf="tennisData[0].openDate > tomorrow"  >
                  <span>No Matches Found !</span>
              </div>
              </div>
            </ng-container>
            <ng-container *ngIf="tennisData?.length == '0' ">
          <div  class ="notmatch"> No Match Found !</div>
                  </ng-container>
            </mat-tab>
        </mat-tab-group>
      </div>
<div *ngIf="id==4">
  <div class="game-list-card">
    <div class="game-list-header">
      <!-- <img src="/assets/images/osgbook/tennis.svg"   class="balimmage"> -->
      Slots
      <!-- ({{tennisData?.length}}) -->
      <mat-divider class="didvide" [vertical]="true"></mat-divider>
    </div>
    <ul class="casinolistgrid">
     
      <!-- <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 6244
                  </div>
        <img  class="imgsize" (click)="openCasino('SPB-aviator')" src="https://client.qtlauncher.com/images/?id=SPB-aviator_en_US&type=logo-square&version=1671525105358"
        alt=""> 
        <div class="game-detail"> <p class="namecasino">
          Casino
        </p> 
        <p class="namecasinom">
          Aviator
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 2L
        </p></div>
      </div></li> -->
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 3938
                  </div>
        <img  class="imgsize" (click)="openCasino('DS-aceydeucey')" src="https://client.qtlauncher.com/images/?id=DS-aceydeucey_en_US&type=logo-square&version=1663155620211"
        alt=""> 

        <div class="game-detail"> <p class="namecasino">
          Casino
        </p> 
        <p class="namecasinom">
          Acey Deucey
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 2L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 9376
                  </div>
        <img  class="imgsize" (click)="openCasino('HAK-blocks')"  src="https://client.qtlauncher.com/images/?id=HAK-blocks_en_US&type=logo-square&version=1695090284433"
        alt=""> 
        <div class="game-detail"><p class="namecasino">
          Casino
        </p> 
        <p class="namecasinom">
          Blocks
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 2L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 8171
                  </div>
        <img  class="imgsize" (click)="openCasino('EVP-bombsquad')" src="https://client.qtlauncher.com/images/?id=EVP-bombsquad_en_US&type=banner&version=1580359967977"
        alt=""> 
        <div class="game-detail"> <p class="namecasino">
          Casino
        </p> 
        <p class="namecasinom">
          Bomb Squad
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 2L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 170
                  </div>
        <img  class="imgsize" (click)="openCasino('EVP-bonanzawheel')"  src="https://client.qtlauncher.com/images/?id=EVP-bonanzawheel_en_US&type=banner&version=1627007856051"
        alt=""> 
        <div class="game-detail"> <p class="namecasino">
          Casino
        </p> 
        <p class="namecasinom">
          Bonanza Wheel
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 2L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 2097
                  </div>
        <img  class="imgsize"  (click)="openCasino('WOO-spinthewheel')"  src="https://client.qtlauncher.com/images/?id=WOO-spinthewheel_en_US&type=logo-square&version=1672321747138"
        alt=""> 
        <div class="game-detail"><p class="namecasino">
          Casino
        </p> 
        <p class="namecasinom">
          Spin the Wheel
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 2L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 3488
                  </div>
        <img  class="imgsize"  (click)="openCasino('EVP-rollthedice')"  src="https://client.qtlauncher.com/images/?id=EVP-rollthedice_en_US&type=logo-square&version=1709649410399"
        alt=""> 
        <div class="game-detail"> <p class="namecasino">
          Casino
        </p> 
        <p class="namecasinom">
          Roll the Dice
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 2L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 341
                  </div>
        <img  class="imgsize" (click)="openCasino('DS-laddergame')"  src="https://client.qtlauncher.com/images/?id=DS-laddergame_en_US&type=logo-square&version=1663187632616"
        alt=""> 
        <div class="game-detail"><p class="namecasino">
          Casino
        </p> 
        <p class="namecasinom">
          Ladder Game
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 2L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 5674
                  </div>
        <img  class="imgsize" (click)="openCasino('EVP-headsandtails')"  src="https://client.qtlauncher.com/images/?id=EVP-headsandtails_en_US&type=banner&version=1538453670896"
        alt=""> 
        <div class="game-detail"><p class="namecasino">
          Casino
        </p> 
        <p class="namecasinom">
          Heads & Tails
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 5L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 3070
                  </div>
        <img  class="imgsize" (click)="openCasino('KIR-igoalengland')"  src="https://client.qtlauncher.com/images/?id=KIR-igoalengland_en_US&type=logo-square&version=1656585205852"
        alt=""> 
        <div class="game-detail"><p class="namecasino">
          Casino
        </p> 
        <p class="namecasinom">
          IGoal England
        </p>
        <p class="namecasino bottomi">
          ₹100 - 2L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 5074
                  </div>
        <img  class="imgsize" (click)="openCasino('EVP-mehen')"  src="https://client.qtlauncher.com/images/?id=EVP-mehen_en_US&type=banner&version=1612775531674"
        alt=""> 
        <div class="game-detail"><p class="namecasino">
          Casino
        </p> 
        <p class="namecasinom">
          Mehen
        </p>
        <p class="namecasino bottomi">
          ₹100 - 3L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 3120
                  </div>
        <img  class="imgsize" (click)="openCasino('EVP-pumpkinmaster')"  src="https://client.qtlauncher.com/images/?id=EVP-pumpkinmaster_en_US&type=logo-square&version=1698125393150"
        alt=""> 
        <div class="game-detail"><p class="namecasino">
          Casino
        </p> 
        <p class="namecasinom">
          Pumpkin Master
        </p>
        <p class="namecasino bottomi">
          ₹100 - 3L
        </p></div>
      </div></li>
  </ul>
   
    </div>
    <div style="margin-bottom:60px">

    </div>
</div>
<div *ngIf="id==5">
  <div class="game-list-card">
    <div class="game-list-header">
      <!-- <img src="/assets/images/osgbook/tennis.svg"   class="balimmage"> -->
    Live Casino
      <!-- ({{tennisData?.length}}) -->
      <mat-divider class="didvide" [vertical]="true"></mat-divider>
    </div>
    <ul  class="casinolistgrid">
      <li><div class="imgframe">

        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 9954
           </div>
        <img  class="imgsize" (click)="openCasino('EVO-americanroulette')" src="https://client.qtlauncher.com/images/?id=EVO-americanroulette_en_US&type=logo-square&version=1678358704943"
        alt=""> 
        <div class="game-detail"> <p class="namecasino">
          Evolution Gaming
        </p> 
        <p class="namecasinom">
          American Roulette
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 25L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 7467
                  </div>
        <img  class="imgsize"(click)="openCasino('EVO-autolightningroulette')"src="https://client.qtlauncher.com/images/?id=EVO-autolightningroulette_en_US&type=logo-square&version=1701277574098"
        alt=""> 
        <div class="game-detail"> <p class="namecasino">
          Evolution Gaming
        </p> 
        <p class="namecasinom">
          Auto Lightning Roulette
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 25K
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 7668
                  </div>
        <img  class="imgsize" (click)="openCasino('EVO-autoroulettelapartage')" src="https://client.qtlauncher.com/images/?id=EVO-autoroulettelapartage_en_US&type=logo-square&version=1702450712159"
        alt=""> 
        <div class="game-detail">  <p class="namecasino">
          Evolution Gaming
        </p> 
        <p class="namecasinom">
          Autoroulette La Partage
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 50K
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 5676
                  </div>
        <img  class="imgsize"  (click)="openCasino('EVO-bacbo')" src="https://client.qtlauncher.com/images/?id=EVO-bacbo_en_US&type=logo-square&version=1678359207526"
        alt=""> 
        <div class="game-detail">  <p class="namecasino">
          Evolution Gaming
        </p> 
        <p class="namecasinom">
          Bac Bo
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 10L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 9880
                  </div>
        <img  class="imgsize" (click)="openCasino('EVO-baccarat')" src="https://client.qtlauncher.com/images/?id=EVO-baccarat_en_US&type=logo-square&version=1678359251397"
        alt=""> 
        <div class="game-detail">   <p class="namecasino">
         Evolution Gaming
        </p> 
        <p class="namecasinom">
          Baccarat
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 13L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 1703
                  </div>
        <img  class="imgsize" (click)="openCasino('EVO-blackjack')" src="https://client.qtlauncher.com/images/?id=EVO-blackjack_en_US&type=logo-square&version=1678359363992"
        alt=""> 
        <div class="game-detail">  <p class="namecasino">
          Evolution Gaming
        </p> 
        <p class="namecasinom">
          Blackjack
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 25L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 9800
                  </div>
        <img  class="imgsize" (click)="openCasino('EVO-cashorcrash')" src="https://client.qtlauncher.com/images/?id=EVO-cashorcrash_en_US&type=logo-square&version=1678359497656"
        alt=""> 
        <div class="game-detail">   <p class="namecasino">
          Evolution Gaming
        </p> 
        <p class="namecasinom">
          Cash or Crash
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 5L
        </p>
        </div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 5077
                  </div>
        <img  class="imgsize" (click)="openCasino('EVO-craps')" src="https://client.qtlauncher.com/images/?id=EVO-craps_en_US&type=logo-square&version=1678360187701"
        alt=""> 
        <div class="game-detail">   <p class="namecasino">
          Evolution Gaming
        </p> 
        <p class="namecasinom">
          Craps
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 25L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 2107
                  </div>
        <img  class="imgsize" (click)="openCasino('EVO-crazycoinflip')" src="https://client.qtlauncher.com/images/?id=EVO-crazycoinflip_en_US&type=logo-square&version=1656329957231"
        alt=""> 
        <div class="game-detail">   <p class="namecasino">
          Evolution Gaming
        </p> 
        <p class="namecasinom">
          Crazy Coin Flip
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 25L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 4352
                  </div>
        <img  class="imgsize" (click)="openCasino('EVO-dragontiger')" src="https://client.qtlauncher.com/images/?id=EVO-dragontiger_en_US&type=logo-square&version=1678379304276"
        alt=""> 
        <div class="game-detail">   <p class="namecasino">
          Evolution Gaming
        </p> 
        <p class="namecasinom">
          Dragon Tiger
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 15L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 3490
                  </div>
        <img  class="imgsize" (click)="openCasino('EVO-dreamcatcher')" src="https://client.qtlauncher.com/images/?id=EVO-dreamcatcher_en_US&type=logo-square&version=1678379368909"
        alt=""> 
        <div class="game-detail">   <p class="namecasino">
          Evolution Gaming
        </p> 
        <p class="namecasinom">
          Dream Catcher
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 25L
        </p></div>
      </div></li>
      <li><div class="imgframe">
        <div class="usercount">
          <mat-icon class="material-icons sizecasino">people</mat-icon> 2177
                  </div>
        <img  class="imgsize" (click)="openCasino('EVO-fantan')" src="https://client.qtlauncher.com/images/?id=EVO-fantan_en_US&type=logo-square&version=1678379688682"
        alt=""> 
        <div class="game-detail">   <p class="namecasino">
          Evolution Gaming
        </p> 
        <p class="namecasinom">
          Fan Tan
        </p> 
        <p class="namecasino bottomi">
          ₹100 - 20L
        </p></div>
      </div></li>
  </ul>
   
    </div>
    <div style="margin-bottom:60px">

    </div>
</div>
<div  *ngIf="id==6">
  <div *ngIf="!res_msg" class="game-list-card">
    <div class="game-list-header">
      <!-- <img src="/assets/images/osgbook/tennis.svg"   class="balimmage"> -->
      Casino 
      <!-- ({{tennisData?.length}}) -->
      <mat-divider class="didvide" [vertical]="true"></mat-divider>
    </div>
    <div class="bottom-menu">
      <mat-list> 
    <mat-list-item (click)="casino_games('LIVECASINO');" [ngClass]="{'active': casino_type === 'LIVECASINO'}">  <span>LIVE CASINO</span></mat-list-item><span class="dash_line" style=" margin-top: 3px; color: #ffff;
    font-size: 1.5rem;font-weight: 100;">|</span>
    <mat-list-item (click)="casino_games('SLOT');" [ngClass]="{'active': casino_type === 'SLOT'}">  <span>SLOTS</span></mat-list-item><span class="dash_line" style=" margin-top: 3px; color: #ffff;
    font-size: 1.5rem;font-weight: 100;">|</span>
    <mat-list-item (click)="casino_games('TABLEGAME');" [ngClass]="{'active': casino_type === 'TABLEGAME'}"> <span>TABLE GAMES</span></mat-list-item> <span class="dash_line" style=" margin-top: 3px; color: #ffff;
    font-size: 1.5rem;font-weight: 100;">|</span>
    <mat-list-item (click)="casino_games('SHOOTING');" [ngClass]="{'active': casino_type === 'SHOOTING'}">  <span>SHOOTING GAMES</span></mat-list-item><span class="dash_line" style=" margin-top: 3px; color: #ffff;
    font-size: 1.5rem;font-weight: 100;">|</span>
    <mat-list-item (click)="casino_games('INSTANTWIN');" [ngClass]="{'active': casino_type === 'INSTANTWIN'}"> <span>INSTANT WIN </span> </mat-list-item>
    <span class="dash_line" style=" margin-top: 3px; color: #ffff;
    font-size: 1.5rem;font-weight: 100;">|</span>
    <mat-list-item (click)="casino_games('SCRATCHCARD');" [ngClass]="{'active': casino_type === 'SCRATCHCARD'}"> <span>SCRATCH CARD </span> </mat-list-item>
    <span class="dash_line" style=" margin-top: 3px; color: #ffff;
    font-size: 1.5rem;font-weight: 100;">|</span>
    <mat-list-item (click)="casino_games('VIRTUAL_SPORTS');" [ngClass]="{'active': casino_type === 'VIRTUAL_SPORTS'}"> <span>VIRTUAL SPORTS </span> </mat-list-item>
    <!-- <span class="dash_line" style=" margin-top: 3px; color: #ffff;
    font-size: 1.5rem;font-weight: 100;">|</span>
    <mat-list-item (click)="casino_games('AURA_CASINO');" [ngClass]="{'active': casino_type === 'AURA_CASINO'}"> <span>AURA CASINO </span> </mat-list-item> -->
 
      </mat-list>
  </div>
    <ul  class="casinolistgrid" *ngIf="casino_data?.length>0">
      <!-- <li *ngIf="casino_type=='INSTANTWIN'"><div class="imgframe" (click)="openCasino('SPB-aviator')" >
        <div class="usercount" >
          <mat-icon class="material-icons sizecasino">people</mat-icon> 34000
                  </div>
        <img  class="imgsize" src="https://client.qtlauncher.com/images/?id=SPB-aviator_en_US&type=logo-square&version=1671525105358"
        alt=""> 
        <div class="game-detail"> <p class="namecasino">
          Casino
        </p> 
        <p class="namecasinom">
         Aviator
        </p> 
        <p class="namecasino bottomi">
          ₹10 - 15L
        </p></div>
      </div></li> -->
      <li *ngFor="let x of casino_data"><div class="imgframe" (click)="openCasino(x.id)">
        <div class="usercount" >
          <mat-icon class="material-icons sizecasino">people</mat-icon> 2340 {{randomArray}}
                  </div>
        <img *ngIf="x.images" class="imgsize" [src]="x.images[1].url" 
        alt=""> 
        <div class="game-detail"> <p class="namecasino">
          Casino
        </p> 
        <p class="namecasinom">
          {{x.name}}
        </p> 
        <p class="namecasino bottomi">
          ₹10 - 5L
        </p></div>
      </div></li>
      
  </ul>
   
    </div>
    <div *ngIf="res_msg" class="errmesage">
      <span id="err_msg">{{res_msg}}</span>
    </div>
    <div  style="margin-bottom:60px">
    </div>
</div>
      <div style="margin-bottom: 8px;"></div>
      <!-- <ng-container *ngIf="!inplay">
        <div *ngIf="id==4" class="game-list-card">
          <div class="game-list-header">
           
            Go Casino

            <mat-divider class="didvide" [vertical]="true"></mat-divider>
          </div>
          <ul>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 3938
                </div>
                <img class="imgsize" (click)="openCasino('SPB-aviator')"
                  src="https://ss.manage63.com/south247/casinolobbyimages/aviator.jpg" alt="">

                <p class="namecasino">
                  Aura Casino
                </p>
                <p class="namecasinom">
                  Aviator 3D
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 2L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 6244
                </div>
                <img class="imgsize" [routerLink]="['/casino']"
                  src="https://ss.manage63.com/south247/casinolobbyimages/blastoff.jpg" alt="">
                <p class="namecasino">
                  Aura Casino
                </p>
                <p class="namecasinom">
                  BlastOff
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 2L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 9376
                </div>
                <img class="imgsize" [routerLink]="['/casino']"
                  src="https://ss.manage63.com/south247/casinolobbyimages/Trio.jpg" alt="">
                <p class="namecasino">
                  Aura Casino
                </p>
                <p class="namecasinom">
                  Trio
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 2L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 8171
                </div>
                <img class="imgsize" [routerLink]="['/casino']"
                  src="https://ss.manage63.com/south247/casinolobbyimages/super-over.jpg" alt="">
                <p class="namecasino">
                  Aura Casino
                </p>
                <p class="namecasinom">
                  Super Over
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 2L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 170
                </div>
                <img class="imgsize" [routerLink]="['/casino']"
                  src="https://ss.manage63.com/south247/casinolobbyimages/3-Card-Judgement.jpg" alt="">
                <p class="namecasino">
                  Aura Casino
                </p>
                <p class="namecasinom">
                  3 Card Judgement
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 2L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 2097
                </div>
                <img class="imgsize" [routerLink]="['/casino']"
                  src="https://ss.manage63.com/south247/casinolobbyimages/Casino-War.jpg" alt="">
                <p class="namecasino">
                  Aura Casino
                </p>
                <p class="namecasinom">
                  Casino War
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 2L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 3488
                </div>
                <img class="imgsize" (click)="openCasino('EVO-dreamcatcher')"
                  src="https://ss.manage63.com/south247/casinolobbyimages/Dream-Catcher.jpg" alt="">
                <p class="namecasino">
                  Aura Casino
                </p>
                <p class="namecasinom">
                  Dream Catcher
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 2L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 341
                </div>
                <img class="imgsize" [routerLink]="['/casino']"
                  src="https://ss.manage63.com/south247/casinolobbyimages/29-Card-Baccarat.jpg" alt="">
                <p class="namecasino">
                  Aura Casino
                </p>
                <p class="namecasinom">
                  29 Card Baccarat
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 2L
                </p>
              </div>
            </li>
          </ul>

        </div>
        <div *ngIf="id==5" class="game-list-card">
          <div class="game-list-header">

            Live Casino

            <mat-divider class="didvide" [vertical]="true"></mat-divider>
          </div>
          <ul>
            <li>
              <div class="imgframe">

                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 9954
                </div>
                <img class="imgsize" (click)="openCasino('EVO-autolightningroulette')"
                  src="https://cdn.dreamdelhi.com/dc/hindi_lightning_roulette.webp" alt="">
                <p class="namecasino">
                  Evolution Gaming
                </p>
                <p class="namecasinom">
                  Hindi Lightning Roulette
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 25L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 7467
                </div>
                <img class="imgsize" (click)="openCasino('EZU-automaticroulette')"
                  src="https://cdn.dreamcasino.live/evo_hindilightningroulette.webp" alt="">
                <p class="namecasino">
                  Evolution Gaming
                </p>
                <p class="namecasinom">
                  Hindi Lightning Roulette
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 25K
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 7668
                </div>
                <img class="imgsize" (click)="openCasino('EZU-sicbo')"
                  src="https://cdn.dreamcasino.live/evo_supersicbo.webp" alt="">
                <p class="namecasino">
                  Evolution Gaming
                </p>
                <p class="namecasinom">
                  Super Sic Bo
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 50K
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 5676
                </div>
                <img class="imgsize" [routerLink]="['/casino']"
                  src="https://cdn.dreamdelhi.com/dc/gold_vault_roulette.webp" alt="">
                <p class="namecasino">
                  Evolution Gaming
                </p>
                <p class="namecasinom">
                  Gold Vault Roulette
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 10L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 9880
                </div>
                <img class="imgsize" (click)="openCasino('BTL-sicbo')"
                  src="https://cdn.dreamcasino.live/evolution/evo_emperorsicbo.webp" alt="">
                <p class="namecasino">
                  Evolution Gaming
                </p>
                <p class="namecasinom">
                  Emperor Sic Bo
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 13L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 1703
                </div>
                <img class="imgsize" (click)="openCasino('EVO-autolightningroulette')"
                  src="https://cdn.dreamcasino.live/evo_lightningroulette.webp" alt="">
                <p class="namecasino">
                  Evolution Gaming
                </p>
                <p class="namecasinom">
                  Lightning Roulette
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 25L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 9800
                </div>
                <img class="imgsize" (click)="openCasino('EVO-doubleballrou001')"
                  src="https://cdn.dreamcasino.live/evo_livedoubleballroulette.webp" alt="">
                <p class="namecasino">
                  Evolution Gaming
                </p>
                <p class="namecasinom">
                  Double Ball Roulette
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 5L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 5077
                </div>
                <img class="imgsize" (click)="openCasino('EVO-americanroulette')"
                  src="https://cdn.dreamcasino.live/evo_liveamericanroulette.webp" alt="">
                <p class="namecasino">
                  Evolution Gaming
                </p>
                <p class="namecasinom">
                  American Roulette
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 25L
                </p>
              </div>
            </li>
          </ul>

        </div>
        <div *ngIf="id==6" class="game-list-card">
          <div class="game-list-header">
            
            Casino

            <mat-divider class="didvide" [vertical]="true"></mat-divider>
          </div>
          <ul>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 6043
                </div>
                <img class="imgsize" (click)="openCasino('SPB-aviator')"
                  src="https://cdn.dreamcasino.live/sbe_aviator.webp" alt="">
                <p class="namecasino">
                  Spribe
                </p>
                <p class="namecasinom">
                  Aviator
                </p>
                <p class="namecasino bottomi">
                  ₹10 - 8K
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 688
                </div>
                <img class="imgsize" [routerLink]="['/casino']"
                  src="https://cdn.dreamdelhi.com/dc/gmz_dragons_secret.webp" alt="">
                <p class="namecasino">
                  Gamzix
                </p>
                <p class="namecasinom">
                  Dragons Secret
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 40K
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 5099
                </div>
                <img class="imgsize" [routerLink]="['/casino']"
                  src="https://cdn.dreamdelhi.com/dc/gmz_the_hottest_game.webp" alt="">
                <p class="namecasino">
                  Gamzix
                </p>
                <p class="namecasinom">
                  The Hottest Game
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 50K
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 2124
                </div>
                <img class="imgsize" [routerLink]="['/casino']"
                  src="https://cdn.dreamdelhi.com/dc/gmz_buffalo_coin_hold_the_spin.webp" alt="">
                <p class="namecasino">
                  Gamzix
                </p>
                <p class="namecasinom">
                  Buffalo Coin: Hold the Spin
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 20K
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 3963
                </div>
                <img class="imgsize" [routerLink]="['/casino']"
                  src="https://osgbook.com/assets/img/default-casino-img.png" alt="">
                <p class="namecasino">
                  Smartsoft Gaming
                </p>
                <p class="namecasinom">
                  Viking
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 1L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 1690
                </div>
                <img class="imgsize" [routerLink]="['/casino']" src="https://cdn.dreamdelhi.com/dc/sms_argo.webp"
                  alt="">
                <p class="namecasino">
                  Smartsoft Gaming
                </p>
                <p class="namecasinom">
                  Argo
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 1L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 1553
                </div>
                <img class="imgsize" [routerLink]="['/casino']" src="https://cdn.dreamdelhi.com/dc/sms_book_of_win.webp"
                  alt="">
                <p class="namecasino">
                  Smartsoft Gaming
                </p>
                <p class="namecasinom">
                  Book of Win
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 2L
                </p>
              </div>
            </li>
            <li>
              <div class="imgframe">
                <div class="usercount">
                  <mat-icon class="material-icons sizecasino">people</mat-icon> 8955
                </div>
                <img class="imgsize" [routerLink]="['/casino']" src="https://cdn.dreamdelhi.com/dc/sms_jetx3.webp"
                  alt="">
                <p class="namecasino">
                  Smartsoft Gaming
                </p>
                <p class="namecasinom">
                  Zetx3
                </p>
                <p class="namecasino bottomi">
                  ₹100 - 1L
                </p>
              </div>
            </li>
          </ul>

        </div>
      </ng-container>   -->
    </div>

    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>

